<template>
    <div ref="root">
        <div class="uk-padding-small">
            <vk-breadcrumb class="uk-margin-remove">
                <vk-breadcrumb-item :disabled="true">Création de ticket</vk-breadcrumb-item>
            </vk-breadcrumb>
        </div>
        <div class="uk-padding-small">
            <div class="m-3">
                <label class="form-label">Lieux</label>
                <multiselect
                    v-model="ticketPayload.property"
                    :loading="isLoading"
                    :options="propertyListFiltered"
                    deselectLabel="Cliquez pour désélectionner"
                    label="name"
                    placeholder="taper un texte pour filtrer les lieux"
                    selectLabel="Cliquez pour sélectionner"
                    track-by="name"
                >
                    <template slot="noOptions">Aucun résultats</template>
                    <template slot="noResult">Aucun élément trouvé</template>
                </multiselect>
            </div>
            <div class="m-3">
                <label class="form-label">Objet du ticket</label>
                <textarea
                    class="form-control rounded-0"
                    rows="15"
                    placeholder="Expliquez la problématique"
                    v-model="ticketPayload.mainPurpose"
                ></textarea>
            </div>
            <div class="m-3">
                <label class="form-label">Documents</label>
                <FileUpload :files-uploaded.sync="ticketPayload.documents" />
            </div>
            <InformedWorkersList @selected:workers="updateTicketWorkers" />
            <div class="text-end mt-5">
                <button :disabled="recordPending" class="btn btn-primary" @click="createTicket">
                    Créer
                    <div v-if="recordPending" class="uk-margin-left uk-inline" uk-spinner="ratio:0.75"></div>
                </button>
            </div>
        </div>
        <vk-modal :show="displayConfirmCreateTicketModal">
            <vk-modal-title>Confirmation</vk-modal-title>
            <p>
                Attention par défaut, toutes les entreprises sous-traitantes et prestataires seront notifiés du
                ticket.<br />
                Sinon veuillez sélectionner les entreprises que vous souhaitez notifier du ticket
            </p>
            <p class="uk-text-right">
                <button style="border-radius: 5px" @click="cancelCreateTicket" class="btn btn-secondary">Non</button>
                <button
                    :disabled="recordPending"
                    style="border-radius: 5px"
                    class="btn btn-warning uk-margin-left"
                    @click="confirmCreateTicket"
                >
                    Oui, créer le ticket
                    <div v-if="recordPending" class="uk-margin-left uk-inline" uk-spinner="ratio:0.75"></div>
                </button>
            </p>
        </vk-modal>
    </div>
</template>

<script>
import FileUpload from '@/components/FileUpload'
import backendApi from '@/backend/api'
import store from '@/store'
import { mapActions, mapState } from 'vuex'
import InformedWorkersList from '@/components/InformedWorkersList'

export default {
    name: 'Tickets',
    components: { InformedWorkersList, FileUpload },
    data: () => ({
        propertyFilterValue: '',
        ticketPayload: {
            property: null,
            mainPurpose: '',
            documents: [],
            workers: [],
            isDelegated: false,
            siteUrl: window.location.protocol + '//' + window.location.host,
        },
        displayConfirmCreateTicketModal: false,
        recordPending: false,
    }),
    computed: {
        ...mapState('properties', { isLoading: 'isLoading', propertyList: 'list' }),
        ...mapState('workers', { workersList: 'list' }),
        propertyListFiltered() {
            const address = this.propertyList.map((item) => {
                return {
                    name: item.address.formattedAddress + ' - ' + item.name,
                    id: item.id,
                }
            })
            return address.filter((item) => item.id)
        },
    },
    methods: {
        ...mapActions('notifications', ['addNotification']),
        createTicket() {
            if (!this.ticketPayload.property) {
                this.addNotification({
                    status: 'warning',
                    message: 'Veuillez sélectionner un lieu',
                })
                return
            }

            if (!this.ticketPayload.mainPurpose) {
                this.addNotification({
                    status: 'warning',
                    message: 'Veuillez saisir un objet',
                })
                return
            }

            if (this.ticketPayload.workers.length === this.workersList.workers.length) {
                this.displayConfirmCreateTicketModal = true
                return
            }

            this.confirmCreateTicket()
        },
        cancelCreateTicket() {
            this.displayConfirmCreateTicketModal = false
        },
        confirmCreateTicket() {
            this.recordPending = true
            backendApi
                .createTicket(store.state.login.user.token, this.ticketPayload)
                .then((data) => {
                    this.$router.push({ name: 'ticket_view', params: { ticketId: data.id } })
                })
                .finally(() => {
                    this.recordPending = false
                })
        },
        updateTicketWorkers(selectedWorkerIds) {
            let workers = []

            for (let i = 0; i < selectedWorkerIds.length; i++) {
                let worker = { id: selectedWorkerIds[i].id }
                workers.push(worker)
            }

            this.ticketPayload.workers = workers
        },
    },
    beforeRouteEnter(to, from, next) {
        Promise.all([store.dispatch('properties/updateProperties'), store.dispatch('workers/updateWorkers')])
            .then(() => {
                next()
            })
            .catch(() => {
                next(false)
            })
    },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped></style>
